<template>
	<v-row>
		<v-dialog v-model="dialog.status" persistent>
			<CardDialog :dialog="dialog" />
		</v-dialog>
		<v-col cols="12" lg="4">
			<v-card class="mx-auto">
				<v-card-title>Informações</v-card-title>
				<v-divider />
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
				<v-card-text v-if="dados.lista">
					<div class="my-3">
						<v-icon class="mr-2">mdi-identifier</v-icon>
						<span class="font-weight-bold">Nº solicitação:</span>
						{{dados.lista[0].idsolicitacao}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account</v-icon>
						<span class="font-weight-bold">Cod. cliente:</span>
						{{dados.lista[0].idcliente}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-office-building</v-icon>
						<span class="font-weight-bold">Filial:</span>
						{{dados.lista[0].filial}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-numeric</v-icon>
						<span class="font-weight-bold">Qtd. produtos:</span>
						{{dados.lista.length}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-clock</v-icon>
						<span class="font-weight-bold">Data de entrega:</span>
						{{dados.lista[0].dtsolicitacao}}
					</div>
					<div class="my-3" v-if="dados.lista[0].cancelado == 'T'">
						<v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
						<span class="font-weight-bold">Status:</span>
						Cancelado
					</div>
					<div class="my-3" v-if="dados.lista[0].status == 'T'">
						<v-icon class="mr-2">mdi-check</v-icon>
						<span class="font-weight-bold">Status:</span>
						Entregue
					</div>
				</v-card-text>
				<template v-if="dados.lista">
					<v-card-actions
						class="pa-4"
						v-if="dados.lista[0].cancelado != 'T' && dados.lista[0].status != 'T'"
					>
						<v-spacer />
						<v-btn color="primary" elevation="0" @click="confirmarEntrega()">Confirmar</v-btn>
						<v-btn color="red" dark elevation="0" @click="cancelarEntrega()">Cancelar</v-btn>
					</v-card-actions>
				</template>
			</v-card>
		</v-col>
		<v-col cols="12" lg="8">
			<v-row>
				<v-col>
					<v-card>
						<v-card-title>Produtos</v-card-title>
						<v-divider />
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-text>
							<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
							<v-simple-table v-else>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Id</th>
											<th class="text-left">Produto</th>
											<th class="text-left">Qtd. à entregar</th>
											<th class="text-left">Saldo disponível</th>
											<th class="text-left">Estoque disponível</th>
											<th class="text-left">Data solicitação</th>
										</tr>
									</thead>
									<tbody>
										<template v-if="dados.lista.length > 0">
											<tr v-for="(u, i) in dados.lista" :key="i">
												<td>{{u.idproduto}}</td>
												<td>{{u.produto}}</td>
												<td v-if="u.qtdproduto" class="text-center">{{u.qtdproduto.toFixed(2)}}</td>
												<td v-else>0.00</td>
												<td v-if="u.qtdaguardando" class="text-center">
													{{u.qtdaguardando.toFixed(2)}}
													<MenuInfo
														v-if="u.qtdproduto > u.qtdaguardando"
														color="red"
														texto="Saldo do cliente inferior ao solicitado"
													/>
												</td>
												<td v-else class="text-center">
													0.00
													<MenuInfo color="red" texto="Saldo do cliente inferior ao solicitado" />
												</td>
												<td v-if="u.qtddisponivel" class="text-center">
													{{u.qtddisponivel.toFixed(2)}}
													<MenuInfo
														v-if="u.qtdproduto > u.qtddisponivel"
														color="red"
														texto="Estoque do produto inferior ao solicitado pelo cliente"
													/>
												</td>
												<td v-else class="text-center">
													0.00
													<MenuInfo color="red" texto="Estoque do produto inferior ao solicitado pelo cliente" />
												</td>
												<td>{{u.dtsolicitacao}}</td>
											</tr>
										</template>
										<template v-else>
											<tr>
												<td class="text-center" colspan="6">Nenhum registro encontrado.</td>
											</tr>
										</template>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";
import MenuInfo from "../Widgets/MenuInfo";

export default {
	name: "EntregaGet",
	components: { CardDialog, MenuInfo },
	props: ["idsolicitacao"],
	data: () => ({
		carregando: false,
		carregandoSkeleton: true,
		dados: [],
		dialog: { status: false },
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		get() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}entrega/get`, {
					idsolicitacao: parseInt(this.idsolicitacao),
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		confirmarEntrega() {
			this.dialog = { status: false };
			return axios
				.post(`${this.backendUrl}entrega/confirmar`, {
					idsolicitacao: parseInt(this.idsolicitacao),
				})
				.then((res) => {
					if (res.data) {
						this.dialog.title = "Entrega confirmada";
						this.dialog.msg =
							"Entrega de mercadoria confirmada com sucesso!";
						this.dialog.icon = "mdi-check-circle-outline";
						this.dialog.iconColor = "success";
						this.dialog.status = true;
						this.get();
					} else {
						this.dialog.title = "Erro";
						this.dialog.msg =
							"Não foi possível confirmar a entrega da mercadoria!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
						this.dialog.status = true;
					}
				})
				.catch(() => {
					this.dialog.title = "Erro";
					this.dialog.msg =
						"Não foi possível confirmar a entrega da mercadoria!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					this.dialog.status = true;
				});
		},
		cancelarEntrega() {
			this.dialog = { status: false };
			return axios
				.post(`${this.backendUrl}entrega/cancelar`, {
					idsolicitacao: parseInt(this.idsolicitacao),
				})
				.then((res) => {
					if (res.data) {
						this.dialog.title = "Entrega cancelada";
						this.dialog.msg =
							"Entrega de mercadoria cancelada com sucesso!";
						this.dialog.icon = "mdi-check-circle-outline";
						this.dialog.iconColor = "success";
						this.dialog.status = true;
						this.get();
					} else {
						this.dialog.title = "Erro";
						this.dialog.msg =
							"Não foi possível cancelar a entrega da mercadoria!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
						this.dialog.status = true;
					}
				})
				.catch(() => {
					this.dialog.title = "Erro";
					this.dialog.msg =
						"Não foi possível cancelar a entrega da mercadoria!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					this.dialog.status = true;
				});
		},
	},
	created() {
		this.get();
	},
};
</script>

<style scoped>
</style>